import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import './PopupMessage.scss';

export enum EPopupType {
    Message,
    Warning,
    Error
}

interface Props {
    hidden?: boolean,
    title?: string,
    message?: string,
    buttonMessage?: string,
    buttonOnClick?: any,
    type: EPopupType,
}

export class PopupMessage extends Component<Props, {}> {
    private getHeaderColor = () => {
        switch(this.props.type) {
            case EPopupType.Message:
                return "#008c45";
            case EPopupType.Warning:
                return "#e2a700";
            case EPopupType.Error:
                return "#cd212a";
        }
    }

    public render() {
        return(
            <div hidden={this.props.hidden} className={'popup-message'}>
                <div hidden={this.props.hidden} className={'dialog'}>
                    <div style={{backgroundColor: this.getHeaderColor()}} className={'header'}>
                        {this.props.title}
                    </div>
                    <div className={'content'}>
                        <div className={'text-wrapper'}>
                            <p>{this.props.message}</p>
                        </div>
                        <div className={'btn-wrapper'}>
                            <Button onClick={this.props.buttonOnClick}>Ok</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
