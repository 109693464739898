import React, {Component, useState} from 'react';
import {Button, Col, Dropdown, Form, Row} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import it from 'date-fns/locale/it';
import axios from 'axios';

import {EQuoteServiceType, QuoteTypeToString} from '../../../classes/Quote/Quote';
import Appointment from '../../../classes/Appointment/Appointment';
import {EPopupType, PopupMessage} from "../../PopupMessage/PopupMessage";
import "react-datepicker/dist/react-datepicker.css";
import './AppointmentForm.scss';
registerLocale('it', it);
setDefaultLocale("it");

interface Props {
    hidden: boolean,
    onBackPressed: any,
}

interface State {
    appointment: Appointment
    selectedService: EQuoteServiceType,
    dropdownText: string,
    showDialog: boolean,
    popupDialogTitle: string,
    popupDialogMessage: string,
    popupDialogType: EPopupType,
    buttonText: string,
    buttonEnabled: boolean,
    pickerDate: Date
}

export default class AppointmentForm extends Component<Props, State> {
    public constructor(props: any) {
        super(props);
        this.state = {
            appointment: new Appointment(),
            selectedService: EQuoteServiceType.None,
            dropdownText: "Seleziona un servizio",
            showDialog: false,
            popupDialogTitle: "Titolo Dialogo",
            popupDialogMessage: "Messaggio Dialogo",
            popupDialogType: EPopupType.Message,
            buttonText: 'Invia richiesta',
            buttonEnabled: true,
            pickerDate: new Date(),

        }
    }

    public render() {
        return(
            <div hidden={this.props.hidden} className={'request-form'}>
                <Button onClick={this.props.onBackPressed} className={'back-button'}>Torna Indietro</Button>
                <div className={'form'}>
                    <h2 className={'heading'}>Prendi appuntamento</h2>
                    <Form className={'input-form'}>
                        <Form.Group controlId="personal-data">
                        <h3 className={'personaldata-heading'}>Dati Personali</h3>
                            <Row>
                                <Col>
                                    <Form.Label style={{fontWeight: 700}}>Nome<span className={'required-label'}>*</span></Form.Label>
                                    <Form.Control className={'personal-name'} onChange={this.handlePersonalNameChange} required type="text" />
                                </Col>
                                <Col>
                                    <Form.Label style={{fontWeight: 700}}>Cognome<span className={'required-label'}>*</span></Form.Label>
                                    <Form.Control className={'personal-surname'} onChange={this.handlePersonalSurnameChange} required type="text" />
                                </Col>
                            </Row>
                            <Form.Label style={{fontWeight: 700}}>Indirizzo email<span className={'required-label'}>*</span></Form.Label>
                            <Form.Control className={'personal-email'} onChange={this.handlePersonalEmailChange} required type="email"/>
                            <Form.Label style={{fontWeight: 700}}>Numero di telefono<span className={'required-label'}>*</span></Form.Label>
                            <Form.Control className={'personal-phone'} onChange={this.handlePersonalPhoneChange} type="text" />
                        </Form.Group>
                        <h3 className={'service-heading'}>Data Appuntamento</h3>
                        <Form.Label style={{fontWeight: 700}}>Servizio richiesto<span className={'required-label'}>*</span></Form.Label>
                        <Dropdown style={{marginTop: '1%', marginBottom: '1%'}}>
                            <Dropdown.Toggle variant="success" id="dropdown-service">
                                {this.state.dropdownText}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => this.handleServiceDropdown(EQuoteServiceType.Repair)}>Riparazione</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.handleServiceDropdown(EQuoteServiceType.Painting)}>Verniciature</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.handleServiceDropdown(EQuoteServiceType.CrystalCare)}>Cura dei cristalli</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.handleServiceDropdown(EQuoteServiceType.HeadlightRestoration)}>Ripristino fari</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.handleServiceDropdown(EQuoteServiceType.LightReplacement)}>Sostituzione luci</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.handleServiceDropdown(EQuoteServiceType.ClimateSanitation)}>Igienizzazione clima</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.handleServiceDropdown(EQuoteServiceType.ClimateRecharge)}>Ricarica del clima</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.handleServiceDropdown(EQuoteServiceType.ParkingSensors)}>Sensori di parcheggio</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Form.Label>Note</Form.Label>
                        <Form.Control onChange={this.handleNotesChange} as="textarea" type="text"/>
                        <div>
                        <Form.Label style={{fontWeight: 700}}>Data Richiesta<span className={'required-label'}>*</span><br/></Form.Label>
                        </div>

                        <DatePicker minDate={new Date()} selected={this.state.pickerDate} onChange={this.onDateChange} />

                        <div className={'btn-wrapper'}>
                            <Button disabled={!this.state.buttonEnabled} onClick={this.handleSubmit} variant="primary" type="submit">
                                {this.state.buttonText}
                            </Button>
                        </div>
                    </Form>
                </div>
                <PopupMessage hidden={!this.state.showDialog} buttonOnClick={this.onModalBtnClicked} title={this.state.popupDialogTitle} message={this.state.popupDialogMessage} type={this.state.popupDialogType}/>
            </div>
        );
    }

    /* Input functions */

    private handleNotesChange = (event: any) => {
        let appointmentObj = this.state.appointment;
        appointmentObj.notes = event.target.value;
        this.setState({
            appointment: appointmentObj
        });
    };

    /* PERSONAL */
    /* Name Change */
    private handlePersonalNameChange = (event: any) => {
        let appointmentObj = this.state.appointment;
        appointmentObj.submitter.name = event.target.value;
        this.setState({
            appointment: appointmentObj,
        });
    };

    private onDateChange = (date: any) => {
        let appointmentObj = this.state.appointment;
        appointmentObj.date = moment(date).format("YYYY-MM-DD");
        this.setState({
            appointment: appointmentObj,
            pickerDate: date,
        });
    };

    /* Surname Change */
    private handlePersonalSurnameChange = (event: any) => {
        let appointmentObj = this.state.appointment;
        appointmentObj.submitter.surname = event.target.value;
        this.setState({
            appointment: appointmentObj,
        });
    };

    /* Email Change */
    private handlePersonalEmailChange = (event: any) => {
        let appointmentObj = this.state.appointment;
        appointmentObj.submitter.email = event.target.value;
        this.setState({
            appointment: appointmentObj,
        });
    };

    /* Phone Change */
    private handlePersonalPhoneChange = (event: any) => {
        let appointmentObj = this.state.appointment;
        appointmentObj.submitter.phone = event.target.value;
        this.setState({
            appointment: appointmentObj,
        });
    };

    private handleSubmit = (e: any) => {
        e.preventDefault();
        this.setState({
            buttonEnabled: false,
            buttonText: 'Invio in corso'
        });
        if (!this.state.appointment.validate()) {
            // @TODO: Show error
            this.setState({
                showDialog: true,
                popupDialogTitle: "Dati inseriti non validi",
                popupDialogMessage: "Per favore, controlla i dati inseriti",
                popupDialogType: EPopupType.Error,
                buttonEnabled: true,
                buttonText: 'Invia richiesta'
            });
            return;
        }
        axios('https://api.autocarrozzeriazanni.com/v1/appointment',
            {
            method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: this.state.appointment,
            }).then((response) => {
                if (response.status === 201) {
                    this.setState({
                        showDialog: true,
                        popupDialogTitle: "Richiesta inviata",
                        popupDialogMessage: "La tua richiesta di appuntamento è stata inviata con successo!",
                        popupDialogType: EPopupType.Message,
                    });
                    return;
                }
        }).catch((err) => {
            this.setState({
                showDialog: true,
                popupDialogTitle: "Errore durante l'invio",
                popupDialogMessage: "La richiesta non è stata inviata per un errore interno. Per favore riprova",
                popupDialogType: EPopupType.Warning
            });
            console.log(err.response);
            return;
        });
    };

    public handleServiceDropdown(selectedService: EQuoteServiceType) {
        let selectedServiceText = "Seleziona un servizio";
        switch(selectedService) {
            case EQuoteServiceType.Repair:
                selectedServiceText = "Riparazione";
                break;
            case EQuoteServiceType.Painting:
                selectedServiceText = "Verniciature";
                break;
            case EQuoteServiceType.CrystalCare:
                selectedServiceText = "Cura di cristalli";
                break;
            case EQuoteServiceType.HeadlightRestoration:
                selectedServiceText = "Ripristino fari";
                break;
            case EQuoteServiceType.LightReplacement:
                selectedServiceText = "Sostituzione luci";
                break;
            case EQuoteServiceType.ClimateSanitation:
                selectedServiceText = "Igienizzazione clima";
                break;
            case EQuoteServiceType.ClimateRecharge:
                selectedServiceText = "Ricarica del clima";
                break;
            case EQuoteServiceType.ParkingSensors:
                selectedServiceText = "Sensori di parcheggio";
                break;
        }
        let appointmentObj = this.state.appointment;
        appointmentObj.type = QuoteTypeToString(selectedService);
        this.setState({
            appointment: appointmentObj,
            selectedService: selectedService,
            dropdownText: selectedServiceText,
        });
    };

    public onModalBtnClicked = () => {
        this.setState({
            showDialog: false,
            buttonEnabled: true,
            buttonText: 'Invia richiesta'
        })
    }
}
