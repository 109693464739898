import React, { Component } from 'react';
import { faMapMarker, faPhone } from "@fortawesome/free-solid-svg-icons";

import './Header.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export class Header extends Component<{}, {}> {
    public render() {
        return(
            <div className={'header'}>
                <div className={'separator'}/>
                <div className={'content'}>
                    <span style={{display: "inline-block", marginBottom:'-3%'}} className={'title'}>AUTOCARROZZERIA <span style={{color: "#008c45", fontWeight: 700}}>ZANNI</span> <span style={{color: "#008c45", fontWeight: 700, fontStyle: 'italic'}}>|</span><span style={{color: "#ffffff", fontWeight: 700, fontStyle: 'italic'}}>|</span><span style={{color: "#cd212a", fontWeight: 700, fontStyle: 'italic'}}>|</span></span>
                    <div className={'break'}/>
                    <span style={{display: "inline-block"}} className={'subtitle'}><FontAwesomeIcon icon={faMapMarker}/> <a target={'_blank'} href={'https://www.google.com/maps/place/Zanni+Massimiliano+Autocarrozzeria/@44.7559202,10.5935088,17z/data=!3m1!4b1!4m5!3m4!1s0x47801915ba816f97:0x94b6800577cc5d41!8m2!3d44.7559164!4d10.5956975'}>Via Don Pellegrino d'Oglio, 25</a> - <FontAwesomeIcon icon={faPhone}/> <a target={'_self'} href={'tel:0522917603'}>0522 917603</a></span>
                </div>
                </div>
        );
    }
}
