import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import './ActionCard.scss';

interface Props {
    title: string,
    icon: IconProp,
    onClick: any,
}

interface State {

}

export class ActionCard extends Component<Props, State> {
    public constructor(props: any) {
        super(props);
    }
    
    public render() {
        return(
            <Card onClick={this.props.onClick} className={'service-card'}>
                <div className={'content'}>
                    <div className={'icon-wrapper'}>
                        <FontAwesomeIcon className={'service-icon'} icon={this.props.icon}/>
                    </div>
                    <div className={'text-wrapper'}>
                        <h4 className={'text'}>{this.props.title}</h4>
                    </div>
                </div>
            </Card>
        );
    }
}
