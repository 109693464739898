export default class Vehicle {

    public constructor() {
        this.brand = "Brand";
        this.model = "Model"
        this.year = "2019";
        this.licensePlate = "LicensePlate";
        this.frameNumber = "";
    }

    public brand: string;
    public model: string;
    public year: string;
    public licensePlate: string;
    public frameNumber: string;
}
