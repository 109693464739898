export default class Submitter {

    public constructor() {
        this.name = "";
        this.surname = "";
        this.phone = "";
        this.email = "";
    }

    public name: string;
    public surname: string;
    public phone: string;
    public email: string;

    public validate(): boolean {
        return (this.name !== "" && this.surname !== "" && this.phone !== "" && this.email !== "");
    }
}
