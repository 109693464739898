import React, {Component} from 'react';
import {Button, Col, Dropdown, Form, Row} from 'react-bootstrap';
import axios from 'axios';

import {EQuoteServiceType, Quote, QuoteTypeToString} from '../../../classes/Quote/Quote';
import {EPopupType, PopupMessage} from "../../PopupMessage/PopupMessage";
import './RequestForm.scss';

interface Props {
    hidden: boolean,
    onBackPressed: any,
}

interface State {
    selectedService: EQuoteServiceType,
    dropdownText: string,
    lightsText: string,
    quote: Quote,
    attachments: Array<any>,
    showDialog: boolean,
    popupDialogTitle: string,
    popupDialogMessage: string,
    popupDialogType: EPopupType,
    buttonText: string,
    buttonEnabled: boolean,
}

export default class RequestForm extends Component<Props, State> {
    public constructor(props: any) {
        super(props);
        this.state = {
            selectedService: EQuoteServiceType.None,
            dropdownText: "Seleziona un servizio",
            lightsText: "Seleziona un tipo di luci",
            quote: new Quote(),
            attachments: new Array<any>(),
            showDialog: false,
            popupDialogTitle: "Titolo Dialogo",
            popupDialogMessage: "Messaggio Dialogo",
            popupDialogType: EPopupType.Message,
            buttonText: 'Invia richiesta',
            buttonEnabled: true,

        }
    }

    public render() {
        return(
            <div hidden={this.props.hidden} className={'request-form'}>
                <Button onClick={this.props.onBackPressed} className={'back-button'}>Torna Indietro</Button>
                <div className={'form'}>
                    <h2 className={'heading'}>Invia la tua richiesta</h2>
                    <Form className={'input-form'}>
                        <Form.Group controlId="personal-data">
                        <h3 className={'personaldata-heading'}>Dati Personali</h3>
                            <Row>
                                <Col>
                                    <Form.Label style={{fontWeight: 700}}>Nome<span className={'required-label'}>*</span></Form.Label>
                                    <Form.Control className={'personal-name'} onChange={this.handlePersonalNameChange} required type="text" />
                                </Col>
                                <Col>
                                    <Form.Label style={{fontWeight: 700}}>Cognome<span className={'required-label'}>*</span></Form.Label>
                                    <Form.Control className={'personal-surname'} onChange={this.handlePersonalSurnameChange} required type="text" />
                                </Col>
                            </Row>
                            <Form.Label style={{fontWeight: 700}}>Indirizzo email<span className={'required-label'}>*</span></Form.Label>
                            <Form.Control className={'personal-email'} onChange={this.handlePersonalEmailChange} required type="email"/>
                            <Form.Label style={{fontWeight: 700}}>Numero di telefono<span className={'required-label'}>*</span></Form.Label>
                            <Form.Control className={'personal-phone'} onChange={this.handlePersonalPhoneChange} required type="text" />
                        </Form.Group>

                        <h3 className={'service-heading'}>Servizio Richiesto</h3>
                        <Form.Label style={{fontWeight: 700}}>Servizio richiesto<span className={'required-label'}>*</span></Form.Label>
                        <Dropdown style={{marginTop: '1%', marginBottom: '1%'}}>
                            <Dropdown.Toggle variant="success" id="dropdown-service">
                                {this.state.dropdownText}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => this.handleServiceDropdown(EQuoteServiceType.Repair)}>Riparazione</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.handleServiceDropdown(EQuoteServiceType.Painting)}>Verniciature</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.handleServiceDropdown(EQuoteServiceType.CrystalCare)}>Cura dei cristalli</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.handleServiceDropdown(EQuoteServiceType.HeadlightRestoration)}>Ripristino fari</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.handleServiceDropdown(EQuoteServiceType.LightReplacement)}>Sostituzione luci</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.handleServiceDropdown(EQuoteServiceType.ClimateSanitation)}>Igienizzazione clima</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.handleServiceDropdown(EQuoteServiceType.ClimateRecharge)}>Ricarica del clima</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.handleServiceDropdown(EQuoteServiceType.ParkingSensors)}>Sensori di parcheggio</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <div hidden={this.state.selectedService !== EQuoteServiceType.Repair}>
                            <Form.Label>Foto veicolo</Form.Label>
                            <h6>Foto 1</h6>
                            <input accept={".jpeg,.png,.jpg"} type="file" name="file" onChange={(e) => this.handleFileChange(e, 0)}/>
                            <h6>Foto 2</h6>
                            <input accept={".jpeg,.png,.jpg"} type="file" name="file" onChange={(e) => this.handleFileChange(e, 1)}/>
                            <h6>Foto 3</h6>
                            <input accept={".jpeg,.png,.jpg"} type="file" name="file" onChange={(e) => this.handleFileChange(e, 2)}/>
                            <h6>Foto 4</h6>
                            <input accept={".jpeg,.png,.jpg"} type="file" name="file" onChange={(e) => this.handleFileChange(e, 3)}/>
                        </div>
                        <Form.Group controlId="vehicle-data">
                            <h3 className={'vehicle-heading'}>Informazioni Auto</h3>
                            <Form.Label style={{fontWeight: 700}}>Targa<span className={'required-label'}>*</span></Form.Label>
                            <Form.Control className={'vehicle-licenseplate'} onChange={this.handleLicensePlateChange} required type="text" />

                            <Form.Label style={{fontWeight: 700}}>Marca<span className={'required-label'}>*</span></Form.Label>
                            <Form.Control className={'vehicle-brand'} onChange={this.handleBrandChange} type="text" />
                            <Form.Label style={{fontWeight: 700}}>Modello<span className={'required-label'}>*</span></Form.Label>
                            <Form.Control className={'vehicle-model'} onChange={this.handleModelChange} type="text" />
                            <Form.Label style={{fontWeight: 700}}>Anno<span className={'required-label'}>*</span></Form.Label>
                            <Form.Control className={'vehicle-year'} onChange={this.handleYearChange} type="text" />
                            <Form.Label>Numero Telaio</Form.Label>
                            <Form.Control className={'vehicle-framenumber'} onChange={this.handleFrameNumberChange} type="text" />
                        </Form.Group>
                        <Form.Label>Note</Form.Label>
                        <Form.Control onChange={this.handleNotesChange} as="textarea" type="text"/>


                        <div className={'btn-wrapper'}>
                            <Button disabled={!this.state.buttonEnabled} onClick={this.handleSubmit} variant="primary" type="submit">
                                {this.state.buttonText}
                            </Button>
                        </div>
                    </Form>
                </div>
                <PopupMessage hidden={!this.state.showDialog} buttonOnClick={this.onModalBtnClicked} title={this.state.popupDialogTitle} message={this.state.popupDialogMessage} type={this.state.popupDialogType}/>
            </div>
        );
    }

    /* Input functions */

    private handleNotesChange = (event: any) => {
        let QuoteObject = this.state.quote;
        QuoteObject.notes = event.target.value;
        this.setState({
            quote: QuoteObject
        });
    };

    /* PERSONAL */
    /* Name Change */
    private handlePersonalNameChange = (event: any) => {
        let QuoteObject = this.state.quote;
        QuoteObject.submitter.name = event.target.value;
        this.setState({
            quote: QuoteObject
        });
    };

    /* Surname Change */
    private handlePersonalSurnameChange = (event: any) => {
        let QuoteObject = this.state.quote;
        QuoteObject.submitter.surname = event.target.value;
        this.setState({
            quote: QuoteObject
        });
    };

    /* Email Change */
    private handlePersonalEmailChange = (event: any) => {
        let QuoteObject = this.state.quote;
        QuoteObject.submitter.email = event.target.value;
        this.setState({
            quote: QuoteObject
        });
    };

    /* Phone Change */
    private handlePersonalPhoneChange = (event: any) => {
        let QuoteObject = this.state.quote;
        QuoteObject.submitter.phone = event.target.value;
        this.setState({
            quote: QuoteObject
        });
    };

    /* VEHICLE */
    /* LicensePlate Change */
    private handleLicensePlateChange = (event: any) => {
        let QuoteObject = this.state.quote;
        QuoteObject.vehicle.licensePlate = event.target.value;
        this.setState({
            quote: QuoteObject
        });
    };

    /* Brand Change */
    private handleBrandChange = (event: any) => {
        let QuoteObject = this.state.quote;
        QuoteObject.vehicle.brand = event.target.value;
        this.setState({
            quote: QuoteObject
        });
    };

    private handleModelChange = (event: any) => {
        let QuoteObject = this.state.quote;
        QuoteObject.vehicle.model = event.target.value;
        this.setState({
            quote: QuoteObject
        });
    };

    /* Year Change */
    private handleYearChange = (event: any) => {
        let QuoteObject = this.state.quote;
        QuoteObject.vehicle.year = event.target.value;
        this.setState({
            quote: QuoteObject
        });
    };

    /* Frame Change */
    private handleFrameNumberChange = (event: any) => {
        let QuoteObject = this.state.quote;
        QuoteObject.vehicle.frameNumber = event.target.value;
        this.setState({
            quote: QuoteObject
        });
    };

    /* QUOTE */

    private handleSubmit = (e: any) => {
        e.preventDefault();
        this.setState({
            buttonEnabled: false,
            buttonText: 'Invio in corso'
        });
        if (!this.state.quote.validate()) {
            // @TODO: Show error
            this.setState({
                showDialog: true,
                popupDialogTitle: "Dati inseriti non validi",
                popupDialogMessage: "Per favore, controlla i dati inseriti",
                popupDialogType: EPopupType.Error,
                buttonEnabled: true,
                buttonText: 'Invia richiesta'
            });
            return;
        }
        let formData = new FormData();
        formData.append('json', JSON.stringify(this.state.quote));
        for(let i = 0; i < this.state.attachments.length; i++) {
            formData.append('attachments', this.state.attachments[i]);
        }
        axios('https://api.autocarrozzeriazanni.com/v1/quote',
            {
            method: 'PUT',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: formData,
            }).then((response) => {
                if (response.status === 201) {
                    this.setState({
                        showDialog: true,
                        popupDialogTitle: "Richiesta inviata",
                        popupDialogMessage: "La tua richiesta di preventivo è stata inviata con successo!",
                        popupDialogType: EPopupType.Message,
                    });
                    return;
                }
        }).catch(() => {
            this.setState({
                showDialog: true,
                popupDialogTitle: "Errore durante l'invio",
                popupDialogMessage: "La richiesta non è stata inviata per un errore interno. Per favore riprova",
                popupDialogType: EPopupType.Warning
            });
            return;
        });
    };

    private handleFileChange = (event: any, n: number) => {
        let attach = this.state.attachments;
        attach.push(event.target.files[0]);
        this.setState({
            attachments: attach
        });
        console.log(event.target.files[0]);
    };

    public handleServiceDropdown(selectedService: EQuoteServiceType) {
        let selectedServiceText = "Seleziona un servizio";
        switch(selectedService) {
            case EQuoteServiceType.Repair:
                selectedServiceText = "Riparazione";
                break;
            case EQuoteServiceType.Painting:
                selectedServiceText = "Verniciature";
                break;
            case EQuoteServiceType.CrystalCare:
                selectedServiceText = "Cura di cristalli";
                break;
            case EQuoteServiceType.HeadlightRestoration:
                selectedServiceText = "Ripristino fari";
                break;
            case EQuoteServiceType.LightReplacement:
                selectedServiceText = "Sostituzione luci";
                break;
            case EQuoteServiceType.ClimateSanitation:
                selectedServiceText = "Igienizzazione clima";
                break;
            case EQuoteServiceType.ClimateRecharge:
                selectedServiceText = "Ricarica del clima";
                break;
            case EQuoteServiceType.ParkingSensors:
                selectedServiceText = "Sensori di parcheggio";
                break;
        }
        let QuoteObject = this.state.quote;
        QuoteObject.type = QuoteTypeToString(selectedService);
        this.setState({
            selectedService: selectedService,
            dropdownText: selectedServiceText,
            quote: QuoteObject
        });
    };

    public onModalBtnClicked = () => {
        this.setState({
            showDialog: false,
            buttonEnabled: true,
            buttonText: 'Invia richiesta'
        })
    }
}
