import Submitter from '../Submitter/Submitter';
import moment from "moment";

export default class Appointment {

    constructor() {
        this.id = "appointment_id";
        this.date = moment(Date()).format("YYYY-MM-DD");
        this.type = "a";
        this.submitter = new Submitter();
        this.notes = "";
    }

    public validate(): boolean {
        return this.submitter.validate();
    }

    id: string;
    date: string;
    type: string;
    submitter: Submitter;
    notes: string;
}
