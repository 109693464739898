import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import {ActionCard} from "../../ActionCard/ActionCard";
import RequestForm from "../RequestForm/RequestForm";
import AppointmentForm from '../AppointmentForm/AppointmentForm';
import { faFirstAid, faTools, faMapMarker, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

import './Home.scss';

interface Props {

}

enum ESelectedService {
    None,
    Quote,
    RoadsideAssistance,
    CourtesyCar,
    Appointment
}

interface State {
    selectedService: ESelectedService;
}

export class Home extends Component<Props, State> {
    public constructor(props: any) {
        super(props);
        this.state = {
            selectedService: ESelectedService.None
        };
    }

    public render() {
        return(
            <div className={'home'}>
                <Container hidden={this.state.selectedService !== ESelectedService.None}>
                    <Row style={{marginTop: '2%', marginBottom: '2%'}}>
                        <Col>
                            <h2 className={'heading-text'}>Seleziona il servizio da te richiesto</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ActionCard onClick={this.onQuoteClick} icon={faTools} title={'Preventivo'}/>
                        </Col>
                        <Col>
                            <ActionCard onClick={this.onAppointmentClick} icon={faCalendarAlt} title={'Appuntamento'}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '1%', marginBottom: '1%'}}></Row>
                    <Row>
                        <Col>
                            <ActionCard onClick={this.onMapMarkerClick} icon={faMapMarker} title={'Trovaci'}/>

                        </Col>
                        <Col>
                            <ActionCard onClick={this.onRoadsideAssistanceClick} icon={faFirstAid} title={'Soccorso Stradale'}/>

                        </Col>
                    </Row>
                </Container>
                <div>
                    <RequestForm onBackPressed={this.onBackBtnClick} hidden={this.state.selectedService !== ESelectedService.Quote}/>
                    <AppointmentForm onBackPressed={this.onBackBtnClick} hidden={this.state.selectedService !== ESelectedService.Appointment} />
                </div>

            </div>
        );
    };
    // 0522 917603

    public onQuoteClick = () => {
        this.setState({
            selectedService: ESelectedService.Quote,
        });
    };

    public onAppointmentClick = () => {
        this.setState({
            selectedService: ESelectedService.Appointment,
        });
    };

    public onRoadsideAssistanceClick = () => {
        window.open("tel:3398574130", "_self");
    };

    public onMapMarkerClick = () => {
        window.open("https://www.google.com/maps/place/Zanni+Massimiliano+Autocarrozzeria/@44.7559202,10.5935088,17z/data=!3m1!4b1!4m5!3m4!1s0x47801915ba816f97:0x94b6800577cc5d41!8m2!3d44.7559164!4d10.5956975", "_blank");
    };

    public onBackBtnClick = () => {
        this.setState({
            selectedService: ESelectedService.None
        });
    };
}
