import Submitter from '../Submitter/Submitter';
import Vehicle from '../Vehicle/Vehicle';

export enum EQuoteServiceType {
    None,
    Repair,
    Painting,
    CrystalCare,
    HeadlightRestoration,
    LightReplacement,
    ClimateSanitation,
    ClimateRecharge,
    ParkingSensors
}

export class Quote {

    public constructor() {
        this.vehicle = new Vehicle();
        this.submitter = new Submitter();
        this.type = "Default";
        this.notes = "";
    }

    public validate(): boolean {
        // Check if own object data
        if (StringToQuoteType(this.type) === EQuoteServiceType.None) {
            return false;
        }
        // Check vehicle sub data
        if (this.vehicle.brand === "" || this.vehicle.model === "" || this.vehicle.licensePlate === "" || this.vehicle.year === "") {
            return false;
        }
        // Check submitter sub data
        if (this.submitter.name === "" || this.submitter.surname === "" || this.submitter.email === "" || this.submitter.phone === "") {
            return false;
        }
        return true;
    }

    type: string;
    vehicle: Vehicle;
    submitter: Submitter;
    notes: string;
}

export function QuoteTypeToString(QuoteEnum: EQuoteServiceType): string {
    switch(QuoteEnum) {
        case EQuoteServiceType.Repair:
            return "repair";
        case EQuoteServiceType.Painting:
            return "painting";
        case EQuoteServiceType.CrystalCare:
            return "crystal-care";
        case EQuoteServiceType.HeadlightRestoration:
            return "headlight-restoration";
        case EQuoteServiceType.LightReplacement:
            return "light-replacement";
        case EQuoteServiceType.ClimateSanitation:
            return "climate-sanitation";
        case EQuoteServiceType.ClimateRecharge:
            return "climate-recharge";
        case EQuoteServiceType.ParkingSensors:
            return "parking-sensors";
        default:
            return "default";
    }
}

export function StringToQuoteType(QuoteString: string): EQuoteServiceType {
    switch(QuoteString) {
        case "repair":
            return EQuoteServiceType.Repair;
        case "painting":
            return EQuoteServiceType.Painting;
        case "crystal-care":
            return EQuoteServiceType.CrystalCare;
        case "headlight-restoration":
            return EQuoteServiceType.HeadlightRestoration;
        case "light-replacement":
            return EQuoteServiceType.LightReplacement;
        case "climate-sanitation":
            return EQuoteServiceType.ClimateSanitation;
        case "climate-recharge":
            return EQuoteServiceType.ClimateRecharge;
        case "parking-sensors":
            return EQuoteServiceType.ParkingSensors;
        default:
            return EQuoteServiceType.None;
    }
}
