import React from 'react';
import { Header } from './components/Header/Header';
import { Home } from './components/Pages/Home/Home';

import './App.scss';

const App: React.FC = () => {
  return (
    <div className="App">
        <Header/>
        <Home/>
    </div>
  );
};

export default App;
